import { PlanDisplayNames, PlanType } from '@dtx-company/true-common/src/types/planType'
/**
 * Provides a display-friendly name for the given PlanType.
 *
 * This should always be used when displaying the name to ensure
 * consistency across the application.
 *
 * The name casing may be changed by js or css for display purposes.
 *
 * @see getPlanIntervalName
 * @see getPlanName
 *
 * @example
 * const PlanHeaderText = styled(Text)`
 *   text-transform: uppercase;
 * `
 * return <PlanHeaderText>{getPlanTypeName(props.planType)}</PlanHeaderText>
 *
 * @example
 * return <>Unlock exclusive {getPlanTypeName(props.planType).toLowerCase()} benefits.</>
 */
export function getPlanTypeName(planType: PlanType): string {
  switch (planType) {
    case PlanType.FREE:
      return PlanDisplayNames.BASIC
    case PlanType.PRO:
      return PlanDisplayNames.PRO
    case PlanType.PRO_PLUS_ORG:
      return PlanDisplayNames.PRO_PLUS_ORG
    case PlanType.PRO_FLEX:
      return PlanDisplayNames.PRO_FLEX
    case PlanType.PILOT:
      return PlanDisplayNames.PILOT
    case PlanType.ENTERPRISE:
      return PlanDisplayNames.ENTERPRISE
    default:
      throw new Error(`Unrecognized plan type: ${planType}`)
  }
}
