import { GET_PAGE_DETAILS, GET_PAGE_PERMISSION } from '@dtx-company/flow-codegen/src/page/queries'
import {
  GetPageDetailsQuery,
  GetPageDetailsQueryVariables,
  PagePermissionQuery
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { pageGqlFetcher } from '../../../services/gqlFetcher'

export async function getPageDetailsObj({ pageId }: { pageId: string }): Promise<PageType | null> {
  let resp = null
  try {
    resp = await pageGqlFetcher<GetPageDetailsQuery, GetPageDetailsQueryVariables>(
      GET_PAGE_DETAILS,
      { id: pageId }
    )
  } catch (e) {
    console.error(e)
  }
  const page = resp?.Page
  if (!page) {
    return null
  }
  return page
}

export async function checkPageOwnership({ pageId }: { pageId: string }): Promise<boolean> {
  let resp = null
  try {
    resp = await pageGqlFetcher<PagePermissionQuery>(GET_PAGE_PERMISSION, {
      pageId
    })
  } catch (e) {
    console.error(e)
  }
  return Boolean(resp?.checkPageOwnership)
}
