import {
  AssetIdentifiers,
  AssetPermission
} from '../components/FlowTeams/types/asset-permissions.types'
import { BoxProps } from '@dtx-company/shared-components/src'
import { EntityTypes } from '../redux/slices/modal'
import { OrgRoleEnum } from '@dtx-company/true-common/src/types/org'
import { PermissionsTypes } from '../types/flowcode'
import { PositionProps } from 'styled-system'

export enum PermissionsValueTypes {
  //currently we don't distinguish between owners and creators of codes. this may change in the future
  Owner = 'flowcode://asset/creator',
  Creator = 'flowcode://asset/creator',
  Editor = 'flowcode://asset/editor',
  Viewer = 'flowcode://asset/viewer'
}

export type PermissionNameType =
  | 'flowcode://asset/creator'
  | 'flowcode://asset/editor'
  | 'flowcode://asset/viewer'

export interface PermissionsProps extends BoxProps, PositionProps {
  setSelectedCollaborators: React.Dispatch<React.SetStateAction<CollaboratorsType[]>>
  selectedCollaborators: CollaboratorsType[]
  setSelectedRemovals?: React.Dispatch<React.SetStateAction<{ entityId: string }[]>>
  selectedRemovals?: { entityId: string }[]
  initialShareWithEveryone?: boolean
  initialFilterCollaboratorsData?: boolean
  initialShareWithEveryoneAccess?: PermissionsTypes
  viewOnly?: boolean
  isOwner?: boolean
  defaultOwnerDetails?: boolean
  ownerEntityId?: string
  ownerFullName?: string
}

export interface PermissionOptionsType {
  access: PermissionsTypes
}

export interface FetchCollboratorsResponseType {
  email?: string
  firstName?: string
  lastName?: string
  ithacaId?: string
  id?: string
  name?: string
  orgRole?: OrgRoleEnum
}

export interface CollaboratorsType {
  name: string
  email?: string
  access: PermissionsTypes
  icon: string
  entityId: string
  entityType: EntityTypes
  initials: string
  role?: OrgRoleEnum
}

export interface GetCollboratorsResponseType {
  collaborators: CollaboratorsType[]
  org: CollaboratorsType
}

export type UpdateAndRemovePermissions = (args: {
  permissionsToUpsert: AssetPermission[]
  permissionsToRemove: AssetPermission[]
  assetId: string
  token: string
  isCodeSubdomain?: boolean
}) => Promise<void>

export interface UpdateAndRemoveCollaboratorsArgs {
  assetId: string
  selectedCollaborators: CollaboratorsType[]
  selectedRemovals: {
    entityId: string
  }[]
}

export interface GetAssetPermissionType {
  assetId: string
  entityPermissions: AssetPermission[]
}

export interface PermissionsAcccessMenuProps extends PositionProps {
  isLast?: boolean
  entityId?: string
  onSelect?: (access: PermissionsTypes, id?: string) => void
  currentAccess: PermissionsTypes
  viewOnly?: boolean
  onCollaboratorChosenForRemoval?: (entityId: string) => void
  isRemoved?: boolean
  showRemove?: boolean
}

export interface PermissionsModalProps {
  open: boolean
  onClose: () => void
  viewOnly?: boolean
  passedBatchId?: string | string[]
  passedDirectoryId?: string | string[]
  isOwner?: boolean
  ownerEntityId?: string
  ownerFullName?: string
  permissionName?: string
  isBatch?: boolean
  isPage?: boolean
  passedPageId?: string
}

export interface EntityPermissionsType {
  entityId: string
  entityName: string
  entityType: number
  permissionName: string
}

export interface GetBatchPermissionsProps {
  batchId: string | string[]
  scopePermissions: boolean
}

export interface GetDirectoryPermissionsProps {
  directoryId: string | string[]
  scopePermissions: boolean
}

export interface GetStudioConfigEntityPermissionsProps {
  configId: string
}

export interface GetBatchPermissionsResponseType {
  batch: {
    entityPermissions: EntityPermissionsType[]
    batchOwner: {
      isOwner: boolean
      user: {
        ithacaId: string
        fullName: string
      }
    }
  }
}

export interface GetDirectoryPermissionsResponseType {
  directory: {
    entityPermissions: EntityPermissionsType[]
    isOwner: boolean
    user: {
      ithacaId: string
      fullName: string
    }
  }
}

export interface GetStudioConfigEntityPermissionsResponseType {
  studioConfigEntityPermissions: string[]
}

export interface UseGetPermissionsProps extends AssetIdentifiers {
  shouldFetch?: boolean
}

export interface UseGetPermissionsReturnType {
  existingCollaborators: CollaboratorsType[]
  isOwner: boolean
  viewOnly: boolean
  ownerFullName: string
  ownerEntityId: string
  permissionName?: string
  loading: boolean
  error?: string
  entityPermissions: EntityPermissionsType[]
  allPermissions: AssetPermission[]
}
