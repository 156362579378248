import { AllPageInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import {
  fetchByBatchId,
  fetchByDirectoryId,
  fetchByPageId,
  fetchByPageTemplateId,
  fetchBySubdomainId
} from './useGetPermissions.utils'
import type {
  CollaboratorsType,
  EntityPermissionsType
} from '@dtx-company/inter-app/src/types/permissions'
import type { IthacaJwt } from '@dtx-company/ithaca-sdk/src'

type AssetIdObj = {
  batchId?: string
  directoryId?: string
  pageId?: string
  subdomainId?: string
  pageTemplateId?: string
}

export type FetchPermissionsArgs = [
  AssetIdObj,
  boolean,
  IthacaJwt,
  AllPageInfoFragment | null | undefined
]

export interface FetchPermissionsResult {
  isOwner: boolean
  entityPermissions: EntityPermissionsType[]
  existingCollaborators: CollaboratorsType[]
  ownerFullName: string
  ownerEntityId: string
}

const defaultResult: FetchPermissionsResult = {
  isOwner: true,
  entityPermissions: [],
  existingCollaborators: [],
  ownerFullName: '',
  ownerEntityId: ''
}

export async function fetchPermissions([
  assetIdObj,
  isBatch,
  jwt,
  thisPage
]: FetchPermissionsArgs): Promise<FetchPermissionsResult> {
  const { batchId, directoryId, pageId, subdomainId, pageTemplateId } = assetIdObj
  if (pageId) {
    return fetchByPageId(pageId, thisPage)
  }
  if (isBatch && batchId) {
    return fetchByBatchId(batchId, jwt.token)
  }
  if (directoryId) {
    return fetchByDirectoryId(directoryId)
  }
  if (pageTemplateId) {
    return fetchByPageTemplateId(pageTemplateId, jwt.token, jwt.ithacaId)
  }
  if (subdomainId) {
    return fetchBySubdomainId(subdomainId, isBatch)
  }
  return defaultResult
}
