import {
  AssetTypes,
  FetchAssetDetails,
  GetAssetDetailsQuery,
  GetAssetDetailsQueryVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import {
  FetchIthacaPermissionsResultType,
  GetRedirectSubdomainPermissionsQuery,
  GetRedirectSubdomainPermissionsQueryVariables
} from '@dtx-company/flow-codegen/src/code/generated.types'
import { GET_FLOWPAGE_SUBDOMAIN_PERMISSIONS } from '@dtx-company/flow-codegen/src/page/queries'
import { GET_REDIRECT_SUBDOMAIN_PERMISSIONS } from '@dtx-company/flow-codegen/src/code/queries'
import { gqlFetcher, pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'

export const getFlowcodeSubdomainPermissions = async (
  subdomainId: string
): Promise<FetchIthacaPermissionsResultType | undefined> => {
  try {
    const permissionsResult = await gqlFetcher<
      GetRedirectSubdomainPermissionsQuery,
      GetRedirectSubdomainPermissionsQueryVariables
    >(GET_REDIRECT_SUBDOMAIN_PERMISSIONS, { subdomainIds: [subdomainId] })
    const permissions = permissionsResult.redirectSubdomainPermissions?.[0]
    if (!permissions || permissions?.result?.error)
      throw new Error(permissions?.result?.error ?? 'error fetching permissions')
    return permissions
  } catch (e) {
    console.error(e)
    sendErrorNotification('Error fetching permissions for this subdomain', e)
  }
}

export const getFlowpageSubdomainPermissions = async (
  subdomainId: string
): Promise<FetchAssetDetails | undefined> => {
  try {
    const permissionsResult = await pageGqlFetcher<
      GetAssetDetailsQuery,
      GetAssetDetailsQueryVariables
    >(GET_FLOWPAGE_SUBDOMAIN_PERMISSIONS, {
      assetIds: [subdomainId],
      assetType: AssetTypes.WhiteLabel
    })
    const permissions = permissionsResult.getAssetDetails?.[0]
    if (!permissions || permissions?.result.error)
      throw new Error(permissions?.result?.error ?? 'error fetching permissions')
    return permissions
  } catch (e) {
    console.error(e)
    sendErrorNotification('Error fetching permissions for this subdomain', e)
  }
}
