import {
  AssetTypes,
  EntityType,
  GetPageTemplatePermissionsQuery,
  GetPageTemplatePermissionsQueryVariables,
  MutationShareAssetsArgs,
  MutationUnshareAssetsArgs,
  ShareAssetsMutation,
  UnshareAssetsMutation
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { GET_PAGE_TEMPLATE_PERMISSIONS } from '@dtx-company/flow-codegen/src/page/queries'
import {
  SHARE_ASSETS_MUTATION,
  UNSHARE_ASSETS_MUTATION
} from '@dtx-company/flow-codegen/src/page/mutations'
import { UpdateAndRemovePermissions } from '../../types/permissions'
import { pageGqlFetcher } from '../../services/gqlFetcher'
import { useCallback } from 'react'

/**
 * Returns the orgs, teams, and users that have access to the template
 */
export const getPageTemplatePermissions = async (
  templateId: string,
  token: string
): Promise<GetPageTemplatePermissionsQuery> => {
  return pageGqlFetcher<GetPageTemplatePermissionsQuery, GetPageTemplatePermissionsQueryVariables>(
    GET_PAGE_TEMPLATE_PERMISSIONS,
    {
      assetIds: [templateId],
      assetType: AssetTypes.Template,
      entityTypes: [EntityType.Org, EntityType.Team, EntityType.User],
      templateId
    },
    token
  )
}

export function useUpdateAndRemovePageTemplateCollaborators(): UpdateAndRemovePermissions {
  return useCallback<UpdateAndRemovePermissions>(
    async ({ permissionsToRemove, permissionsToUpsert, assetId, token }) => {
      const requests: Promise<ShareAssetsMutation | UnshareAssetsMutation>[] = []

      if (permissionsToUpsert.length) {
        requests.push(
          sharePageTemplatePermissions(
            {
              assetIds: [assetId],
              assetType: AssetTypes.Template,
              entityPermissions: permissionsToUpsert.map(permission => ({
                entityId: permission.entityId,
                entityType: permission.entityType,
                permissionName: permission.permissionName
              }))
            },
            token
          )
        )
      }

      if (permissionsToRemove.length) {
        requests.push(
          unsharePageTemplatePermissions(
            {
              assetIds: [assetId],
              assetType: AssetTypes.Template,
              entityIds: permissionsToRemove.map(permissionToRemove => permissionToRemove.entityId)
            },
            token
          )
        )
      }
      await Promise.all(requests)
    },
    []
  )
}

export const sharePageTemplatePermissions = async (
  variables: MutationShareAssetsArgs,
  token: string
): Promise<ShareAssetsMutation> => {
  return pageGqlFetcher(SHARE_ASSETS_MUTATION, variables, token)
}

export const unsharePageTemplatePermissions = async (
  variables: MutationUnshareAssetsArgs,
  token: string
): Promise<UnshareAssetsMutation> => {
  return pageGqlFetcher(UNSHARE_ASSETS_MUTATION, variables, token)
}
