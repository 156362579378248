import { gql } from 'graphql-request'

export const BATCH_PERMISSIONS_DETAIL_QUERY = /* GraphQL */ `
  query batchPermissionsDetails($batchId: ID!, $scopePermissions: Boolean) {
    batch(batchId: $batchId, scopePermissions: $scopePermissions) {
      id
      entityPermissions {
        entityId
        entityName
        entityType
        permissionName
      }
      batchOwner {
        isOwner
        user {
          ithacaId
          fullName
        }
      }
    }
  }
`
export const DIRECTORY_PERMISSIONS_DETAIL_QUERY = /* GraphQL */ `
  query directoryPermissionsDetails($directoryId: ID!, $scopePermissions: Boolean) {
    directory(directoryId: $directoryId, scopePermissions: $scopePermissions) {
      id
      entityPermissions {
        entityId
        entityName
        entityType
        permissionName
      }
      isOwner
      user {
        ithacaId
        fullName
      }
    }
  }
`

export const STUDIO_CONFIG_ENTITY_PERMISSIONS_DETAIL_QUERY = gql`
  query studioConfigEntityPermissionsDetails($configId: ID!) {
    studioConfigEntityPermissions(configId: $configId)
  }
`

export const PAGE_PERMISSION = gql`
  query pagePermissionsDetails($pageId: ID!) {
    Page(id: $pageId) {
      permission {
        entityId
        entityName
        entityType
        permissionName
      }
    }
  }
`

export const SHARE_ASSET_PERMISSIONS = gql`
  mutation shareAssetPermission(
    $pageIds: [ID!]!
    $entityId: String!
    $entityType: Int!
    $sharedPermission: SharedAssetPermissions!
  ) {
    shareAssetPermission(
      pageIds: $pageIds
      entityId: $entityId
      entityType: $entityType
      sharedPermission: $sharedPermission
    ) {
      pageId
      result {
        success
        error
      }
    }
  }
`

export const DELETE_ASSET_PERMISSIONS = gql`
  mutation deleteAssetPermission($pageIds: [ID!]!, $entityId: String!) {
    deleteAssetPermission(pageIds: $pageIds, entityId: $entityId) {
      pageId
      result {
        success
        error
      }
    }
  }
`
