import { LinkInfoFragment, allPageInfoFrag } from './fragments'
import { gql } from 'graphql-request'

export const CHANGE_PAGE_FLOWCODE_URL = gql`
  mutation changePageFlowcode($batchId: String!, $slugName: String!) {
    changePageFlowcode(batchId: $batchId, slugName: $slugName) {
      shortUrl
    }
  }
`

export const DELETE_PAGE = gql`
  mutation deletePage($id: ID!) {
    deletePage(id: $id)
  }
`

export const TRANSFER_PAGE_PERMISIONS = gql`
  mutation transferPage($pageId: ID!, $newOwnerEmail: String!) {
    transferPage(pageId: $pageId, newOwnerEmail: $newOwnerEmail) {
      id
      displayName
      email
      shortUrl
      profileImage
      caption
      active
      slugName
      share
      isIndexed
      createdAt
      links {
        id
        active
        provider
        type
        title
        description
        thumbNailImgUrl
        displayType
        order
        embed
        actionData
      }
      theme {
        id
        primaryColor
        style
        profileImageMaskType
        backgroundImgUrl
      }
    }
  }
`

export const SUBMIT_VOTE = gql`
  mutation submitVote($vote: SubmitVote!) {
    submitVote(vote: $vote) {
      id
      actionData
      order
      active
      type
      title
      description
      thumbNailImgUrl
      displayType
      provider
      embed
    }
  }
`

export const RESTORE_PAGE = gql`
  mutation restorePage($slugName: String!, $domainServiceSubdomainId: String) {
    restorePage(slugName: $slugName, domainServiceSubdomainId: $domainServiceSubdomainId)
  }
`

export const UPDATE_SLOT = gql`
  mutation updateSlot(
    $slotId: ID!
    $input: KVInput
    $templateConfigInput: TemplateInput
    $pageId: ID
  ) {
    updateSlot(
      slotId: $slotId
      input: $input
      templateConfigInput: $templateConfigInput
      pageId: $pageId
    ) {
      id
      data
    }
  }
`

export const DELETE_SLOT = gql`
  mutation deleteSlot($slotId: ID!, $pageId: ID) {
    deleteSlot(slotId: $slotId, pageId: $pageId)
  }
`

export const CAPTURE_CONTACT = gql`
  mutation captureContactInfo($info: ContactInfoCapture!) {
    captureContactInfo(info: $info)
  }
`

export const CREATE_SLOT = gql`
  mutation createSlot(
    $pageId: ID!
    $slotMetaType: String!
    $value: JSONObject!
    $templateConfigInput: TemplateInput
  ) {
    createSlot(
      pageId: $pageId
      slotMetaType: $slotMetaType
      value: $value
      templateConfigInput: $templateConfigInput
    ) {
      id
      data
    }
  }
`

export const BLOCK_DELETE_PAGE = gql`
  mutation blockAndDeletePage($slugName: String!) {
    blockAndDeletePage(slugName: $slugName)
  }
`

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($email: String!, $removePages: Boolean) {
    deleteAccount(email: $email, removePages: $removePages)
  }
`

export const RENAME_SLUG = gql`
  mutation renameSlug($oldSlug: String!, $newSlug: String!, $domainServiceSubdomainId: ID = null) {
    renameSlug(
      oldSlug: $oldSlug
      newSlug: $newSlug
      domainServiceSubdomainId: $domainServiceSubdomainId
    ) {
      id
      displayName
      email
      shortUrl
      profileImage
      caption
      active
      slugName
      share
      isIndexed
      createdAt
      links {
        id
        active
        provider
        type
        title
        description
        thumbNailImgUrl
        displayType
        order
        embed
        actionData
      }
      theme {
        id
        primaryColor
        style
        profileImageMaskType
        backgroundImgUrl
      }
    }
  }
`

export const TOGGLE_VERIFIED = gql`
  mutation toggleVerified($slugName: String!) {
    toggleVerified(slugName: $slugName) {
      id
      displayName
      email
      shortUrl
      profileImage
      caption
      active
      slugName
      share
      isIndexed
      verified
      createdAt
      links {
        id
        active
        provider
        type
        title
        description
        thumbNailImgUrl
        displayType
        order
        embed
        actionData
      }
      theme {
        id
        primaryColor
        style
        profileImageMaskType
        backgroundImgUrl
        templateConfig {
          order
          fields {
            name
            locked
            required
          }
          lockAll
          requireAll
        }
      }
    }
  }
`
export const BULK_DUPLICATE_PAGE = gql`
  mutation bulkDuplicatePages($pageId: String!, $amount: Int!, $email: String!, $path: String) {
    bulkDuplicatePages(pageId: $pageId, amount: $amount, email: $email, path: $path) {
      id
      slugName
    }
  }
`

export const ADD_MAILCHIMP = gql`
  mutation addMailchimp($apiKey: String!, $pageId: ID!) {
    addMailchimp(apiKey: $apiKey, pageId: $pageId)
  }
`

export const EDIT_MAILCHIMP = gql`
  mutation editMailchimp($apiKey: String!, $pageId: ID!) {
    editMailchimp(apiKey: $apiKey, pageId: $pageId)
  }
`

export const HAS_MAILCHIMP = gql`
  query hasMailchimp($pageId: ID!) {
    hasMailchimp(pageId: $pageId)
  }
`

export const DELETE_MAILCHIMP = gql`
  mutation deleteMailchimp($pageId: ID!) {
    deleteMailchimp(pageId: $pageId)
  }
`

export const AUTH_GOOGLE_SHEETS = gql`
  query authorizeGoogleSheets {
    authorizeGoogleSheets
  }
`

export const HAS_GOOGLE_SHEETS = gql`
  query hasGooglesheets {
    hasGooglesheets
  }
`

export const HAS_VALID_GOOGLE_SHEET_ID = gql`
  query hasSpreadsheet($spreadsheetId: String!) {
    hasValidGooglesheetId(spreadsheetId: $spreadsheetId)
  }
`

export const ACCOUNT_CREATE_OR_UPDATE_HUBSPOT_INTEGRATION = gql`
  mutation createOrUpdateHubSpotIntegration($apiKey: String!) {
    createOrUpdateHubSpotIntegration(apiKey: $apiKey)
  }
`
export const DELETE_HUBSPOT_INTEGRATION = gql`
  mutation deleteHubSpotIntegration {
    deleteHubSpotIntegration
  }
`

export const CREATE_LINK = gql`
  mutation createLink($pageId: ID!, $linkInput: LinkInput!) {
    createLink(pageId: $pageId, linkInput: $linkInput) {
      ...LinkInfo
    }
  }
  ${LinkInfoFragment}
`

export const EDIT_LINK = gql`
  mutation editLink($linkId: ID!, $linkInput: LinkInput!) {
    editLink(linkId: $linkId, linkInput: $linkInput) {
      ...LinkInfo
    }
  }
  ${LinkInfoFragment}
`

export const DELETE_LINK = gql`
  mutation deleteLink($linkId: ID!, $pageId: ID) {
    deleteLink(linkId: $linkId, pageId: $pageId)
  }
`

export const CREATE_OR_EDIT_LINK_THEME = gql`
  mutation createOrEditLinkTheme($linkIds: [ID!]!, $linkThemeInput: LinkThemeInput!) {
    createOrEditLinkTheme(linkIds: $linkIds, linkThemeInput: $linkThemeInput) {
      id
      active
      fontColor
      borderColor
      textSize
      linkColor
      shadowColor
    }
  }
`

export const EDIT_THEME = gql`
  mutation editTheme($themeId: ID!, $themeInput: ThemeInput!) {
    editTheme(themeId: $themeId, themeInput: $themeInput) {
      id
      primaryColor
      style
      profileImageMaskType
      backgroundImgUrl
      linkColor
      borderColor
      shadowColor
      textSize
      fontColor
      templateConfig {
        order
        fields {
          name
          locked
          required
        }
        lockAll
        requireAll
      }
    }
  }
`
export const CREATE_ASSET_LABELS = gql`
  mutation createAssetTags($tagNames: [String!]!, $pageId: ID!) {
    createAssetTags(tagNames: $tagNames, pageId: $pageId) {
      id
    }
  }
`

export const DELETE_ASSET_LABELS = gql`
  mutation deleteAssetTags($tagIds: [ID!]!) {
    deleteAssetTags(tagIds: $tagIds)
  }
`

export const EDIT_PAGE_INFO = gql`
  mutation editPage($editPage: EditPageInput!) {
    editPage(editPage: $editPage) {
      ...AllPageInfo
    }
  }
  ${allPageInfoFrag}
`

export const CREATE_PAGE = gql`
  mutation createPage($page: CreatePageInput!) {
    createPage(page: $page) {
      ...AllPageInfo
    }
  }
  ${allPageInfoFrag}
`

export const CREATE_PAGE_FROM_TEMPLATE = gql`
  mutation createPageFromTemplate(
    $slugName: String!
    $templateId: ID!
    $domainServiceSubdomainId: String = null
    $directoryId: Float
    $path: String
    $pageType: PageType = null
  ) {
    createPageFromTemplate(
      slugName: $slugName
      templateId: $templateId
      domainServiceSubdomainId: $domainServiceSubdomainId
      directoryId: $directoryId
      path: $path
      pageType: $pageType
    ) {
      ...AllPageInfo
    }
  }
  ${allPageInfoFrag}
`

export const TOGGLE_PAGE_INDEXED = gql`
  mutation toggleIsIndex($pageId: ID!) {
    togglePageIndexed(pageId: $pageId)
  }
`

export const AUTHORIZE_SALESFORCE = gql`
  mutation authorizeSalesforceIntegration($domain: String!) {
    authorizeSalesforceIntegration(domain: $domain)
  }
`

export const VERIFY_SALESFORCE = gql`
  mutation verifySalesforceIntegration($code: String!) {
    verifySalesforceIntegration(code: $code)
  }
`

export const DELETE_SALESFORCE = gql`
  mutation deleteSalesforceIntegration {
    deleteSalesforceIntegration
  }
`

export const VERIFY_GSHEETS = gql`
  mutation verifyGoogleSheetsIntegration($code: String!) {
    verifyGoogleSheetsIntegration(code: $code)
  }
`
//WhiteLabel domains

export const CREATE_PAGE_SUBDOMAIN = /* GraphQL */ `
  mutation createPageSubdomain($subdomain: String!, $domainId: String!) {
    createPageSubdomain(subdomain: $subdomain, domainId: $domainId) {
      id
      domain
      isActive
      domainServiceSubdomainId
    }
  }
`

export const RESTORE_PAGE_SUBDOMAIN = /* GraphQL */ `
  mutation restorePageSubdomain($domainServiceSubdomainId: String!) {
    restorePageSubdomain(domainServiceSubdomainId: $domainServiceSubdomainId)
  }
`

export const DELETE_PAGE_SUBDOMAIN = /* GraphQL */ `
  mutation deletePageSubdomain($subdomainId: String!) {
    deletePageSubdomain(subdomainId: $subdomainId)
  }
`

export const REFRESH_PAGE_SUBDOMAIN_STATUS = /* GraphQL */ `
  mutation refreshPageSubdomainStatus($domainServiceSubdomainId: String!) {
    refreshPageSubdomainStatus(domainServiceSubdomainId: $domainServiceSubdomainId)
  }
`

// Flowpage Templates
export const CREATE_PAGE_TEMPLATE = gql`
  mutation createPageTemplate($useDuplicate: Boolean, $input: CreateTemplateInput!) {
    createTemplate(useDuplicate: $useDuplicate, input: $input) {
      id
      name
    }
  }
`

export const ASSIGN_TEMPALTE_PLANS_MUTATION = gql`
  mutation assignTemplatePlans($planIds: [String!]!, $id: ID!, $displayToAll: Boolean) {
    assignTemplatePlans(planIds: $planIds, id: $id, displayToAll: $displayToAll)
  }
`

export const SHARE_ASSETS_MUTATION = gql`
  mutation shareAssets(
    $assetType: AssetTypes!
    $entityPermissions: [EntityPermissionInput!]!
    $assetIds: [String!]!
  ) {
    shareAssets(assetType: $assetType, entityPermissions: $entityPermissions, assetIds: $assetIds) {
      result {
        success
        error
      }
    }
  }
`

export const UNSHARE_ASSETS_MUTATION = gql`
  mutation unshareAssets($assetType: AssetTypes!, $entityIds: [String!]!, $assetIds: [String!]!) {
    unshareAssets(assetType: $assetType, entityIds: $entityIds, assetIds: $assetIds) {
      result {
        success
        error
      }
    }
  }
`

export const EDIT_FLOWPAGE_TEMPLATE_MUTATION = gql`
  mutation editPageTemplate($input: EditTemplateInput!, $id: ID!) {
    editTemplate(input: $input, id: $id) {
      id
    }
  }
`

export const DELETE_FLOWPAGE_TEMPLATE_MUTATION = gql`
  mutation deletePageTemplate($id: ID!) {
    deleteTemplate(id: $id)
  }
`

export const RESET_LINK_THEMES = gql`
  mutation resetLinkThemes($pageId: ID!) {
    resetLinkThemes(pageId: $pageId)
  }
`
export const DELETE_THEME = gql`
  mutation deleteTheme($themeId: ID!) {
    deleteTheme(themeId: $themeId)
  }
`

export const ENABLE_POAP_INTEGRATION = gql`
  mutation enablePoapIntegration($pageId: ID!) {
    enablePoapIntegration(pageId: $pageId)
  }
`

// Moving assets
export const MOVE_ASSET_MUTATION = gql`
  mutation moveAsset($beforeId: ID, $id: ID!, $assetType: MoveAssetType!) {
    moveAsset(beforeId: $beforeId, id: $id, assetType: $assetType)
  }
`

export const MOVE_LINK_MUTATION = gql`
  mutation moveLink($input: MoveLinkInput!) {
    moveLink(input: $input) {
      ...LinkInfo
    }
  }
  ${LinkInfoFragment}
`
