import * as assets from '../assets'
import { ErrorType } from '../types/error.types'
import {
  PermissionNameType,
  PermissionOptionsType
} from '@dtx-company/inter-app/src/types/permissions'
import { PermissionsTypes } from '@dtx-company/inter-app/src/types/flowcode'

export const NON_ALPHA_NUMERIC_REGEX = new RegExp(/[^a-zA-Z\d]/, 'g')

export const PERMISSIONS_OPTIONS: Readonly<PermissionOptionsType[]> = [
  {
    access: PermissionsTypes.VIEWER
  },
  {
    access: PermissionsTypes.EDITOR
  }
]

export const COLLABORATORS_BACKGROUND_IMAGES = [
  assets.gradientBackgroundBlue,
  assets.gradientBackgroundPink,
  assets.gradientBackgroundYellow
]

export const CREATE_ASSET_PERMISSION_ERROR: ErrorType = {
  code: 'CREATE_ASSET_PERMISSION_ERROR',
  message: 'Error creating asset permissions.'
}

export const UPDATE_ASSET_PERMISSION_ERROR: ErrorType = {
  code: 'UPDATE_ASSET_PERMISSION_ERROR',
  message: 'Error updating asset permissions.'
}

export const REMOVE_ASSET_PERMISSION_ERROR: ErrorType = {
  code: 'REMOVE_ASSET_PERMISSION_ERROR',
  message: 'Error removing asset permissions.'
}

export const GET_ASSET_PERMISSION_ERROR: ErrorType = {
  code: 'GET_ASSET_PERMISSION_ERROR',
  message: 'Error fetching asset permissions.'
}

export const permissionsNames: Record<PermissionNameType, PermissionsTypes> = {
  //Since we can't distiguish between the creator and the owner of a Flowcode on the backend right now, we choose the more general label of 'owner' when we see flowcode://asset/creator
  'flowcode://asset/creator': PermissionsTypes.OWNER,
  'flowcode://asset/editor': PermissionsTypes.EDITOR,
  'flowcode://asset/viewer': PermissionsTypes.VIEWER
}

export enum AssetIdTypes {
  BATCH = 'batch',
  DIRECTORY = 'directory'
}
